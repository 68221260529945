import React, { PureComponent } from 'react';
import { Button, Alert, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { commitMutation, createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Media } from '../../types';
import relayEnvironment from '../../Relay';
import MediaDeleteModal from './MediaDeleteModal';
import MediaFormModal from './MediaFormModal';

type ModalType = 'create' | 'delete';

interface GalleryProps {
  media: Media[];
  parentId?: string;
  saveMutation?: any;
  deleteMutation?: any;
}

interface GalleryState {
  modalMedia: any;
  modalMediaIndex: number;
  openModal?: ModalType;
  hasError?: boolean;
}

class Gallery extends PureComponent<GalleryProps, GalleryState> {
  constructor(props: GalleryProps) {
    super(props);

    this.state = {
      openModal: undefined,
      modalMedia: {},
      modalMediaIndex: -1,
      hasError: false,
    };
  }

  private canDeleteMedia = (): boolean => {
    const { deleteMutation } = this.props;

    return !!deleteMutation;
  };

  private onCloseModal = (): void => {
    this.setState({
      openModal: undefined,
      modalMedia: {},
      modalMediaIndex: -1,
      hasError: false,
    });
  };

  private onMediaClick = (media: any, index: number): void => {
    if (this.canDeleteMedia()) {
      this.setState({
        openModal: 'delete',
        modalMedia: media,
        modalMediaIndex: index,
        hasError: false,
      });
    }
  };

  private onMediaCreate = (): void => {
    this.setState({
      openModal: 'create',
      modalMedia: {},
      modalMediaIndex: -1,
      hasError: false,
    });
  };

  private saveMedia = (values: any): void => {
    const { saveMutation, parentId } = this.props;

    commitMutation(relayEnvironment, {
      mutation: saveMutation,
      variables: {
        input: {
          id: parentId,
          media: values,
        },
      },
      onCompleted: (response: any, errors: any) => {
        if (errors) {
          this.setState({ hasError: true });
        } else {
          this.onCloseModal();
        }
      },
      onError: () => {
        this.setState({ hasError: true });
      },
    });
  };

  private deleteMedia = (mediaIndex: number): void => {
    const { deleteMutation, parentId } = this.props;

    if (mediaIndex >= 0) {
      commitMutation(relayEnvironment, {
        mutation: deleteMutation,
        variables: {
          input: {
            id: parentId,
            mediaIndex,
          },
        },
        onCompleted: (response: any, errors: any) => {
          if (errors) {
            this.setState({ hasError: true });
          } else {
            this.onCloseModal();
          }
        },
        onError: () => {
          this.setState({ hasError: true });
        },
      });
    }
  };

  private renderMedia(): React.ReactNode {
    const { media } = this.props;
    let content: any = (
      <Alert color="secondary" className="mt-3 text-center w-100">
        No media found
      </Alert>
    );

    if (media && media.length > 0) {
      content = media.map((singleMedia: Media, index: number) => (
        <Button
          key={singleMedia.url}
          className="media-gallery__item"
          onClick={() => this.onMediaClick(singleMedia, index)}
          disabled={!this.canDeleteMedia()}
        >
          <img src={singleMedia.url} alt="" />
        </Button>
      ));
    }

    return content;
  }

  public render(): React.ReactNode {
    const { saveMutation, deleteMutation } = this.props;
    const { openModal, modalMedia, modalMediaIndex, hasError } = this.state;

    return (
      <>
        <Row>
          <Col lg="12">
            <Card>
              <CardHeader>
                <i className="fa fa-image" />
                Media
                {saveMutation && (
                  <div className="card-header-actions">
                    <Button
                      className="card-header-action btn"
                      color="link"
                      onClick={this.onMediaCreate}
                    >
                      <i className="fa fa-plus" />
                      &nbsp;Add new
                    </Button>
                  </div>
                )}
              </CardHeader>
              <CardBody>
                <div className="media-gallery">{this.renderMedia()}</div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {deleteMutation && (
          <MediaDeleteModal
            isOpen={openModal === 'delete'}
            toggle={this.onCloseModal}
            onDelete={() => this.deleteMedia(modalMediaIndex)}
            media={modalMedia}
            hasError={hasError}
          />
        )}
        {saveMutation && (
          <MediaFormModal
            isOpen={openModal === 'create'}
            onToggle={this.onCloseModal}
            onSave={this.saveMedia}
            hasError={hasError}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(Gallery, {
  media: graphql`
    fragment Gallery_media on Media @relay(plural: true) {
      url
      type
    }
  `,
});
