import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import Markdown from 'react-markdown';
import { Utils as QbUtils } from 'react-awesome-query-builder';
import formatAddress from './formatAddress';
import formatCurrency from './formatCurrency';
import { RecommendationsTypeMapping, formConditionsFieldConfig } from '../constants';
import LinksList from '../components/linksList';
import { enumToLabel } from './enumToLabel';

type CustomFormatValue = (value: any, node: any) => any;

interface FormatRecommendationDataConfig {
  key: string;
  formatValue?: CustomFormatValue | string;
}

export default function formatRecommendationData(
  config: FormatRecommendationDataConfig,
  recommendation: any,
): React.ReactNode {
  /* eslint-disable-next-line no-underscore-dangle */
  const typeDetails = RecommendationsTypeMapping[recommendation.__typename];
  let value = recommendation[config.key];

  if (typeof config.formatValue === 'function') {
    value = config.formatValue(value, recommendation);
  } else {
    switch (config.formatValue) {
      case 'boolean':
        value = value ? 'True' : 'False';
        break;
      case 'address':
        value = formatAddress(recommendation);
        break;
      case 'currency':
        value = formatCurrency(value);
        break;
      case 'array':
        value = value?.join(', ') ?? '';
        break;
      case 'markdown':
        value = <Markdown className="border border-light p-2" source={value} />;
        break;
      case 'enabledBadge':
        value = value ? (
          <Badge color="success">Enabled</Badge>
        ) : (
          <Badge color="secondary">Disabled</Badge>
        );
        break;
      case 'link':
        value = <Link to={`/${typeDetails.baseUrl}/${recommendation.id}`}>{value}</Link>;
        break;
      case 'links':
        value = <LinksList links={value} />;
        break;
      case 'conditions':
        value = value
          ? QbUtils.queryString(QbUtils.loadTree(JSON.parse(value)), formConditionsFieldConfig)
          : 'N/A';
        break;
      case 'enum':
        // eslint-disable-next-line no-case-declarations
        const arrayValue = Array.isArray(value) ? value : [value];
        value = arrayValue
          .map((singleValue: string) => (singleValue ? enumToLabel(singleValue) : undefined))
          .join(', ');
        break;
      default:
        break;
    }
  }

  return value;
}
