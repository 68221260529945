import Amplify, { Auth } from 'aws-amplify';
import env from './env';

const awsAuthSettings = env.getAwsAuthSettings();
const awsAppSyncSettings = env.getAwsAppSyncSettings();

const awsConfig = {
  Auth: {
    identityPoolId: awsAuthSettings.identityPoolId,
    region: awsAuthSettings.region,
    userPoolId: awsAuthSettings.userPoolId,
    userPoolWebClientId: awsAuthSettings.clientId,
    mandatorySignIn: true,
    cookieStorage: {
      domain: awsAuthSettings.cookieDomain,
      path: '/',
      expires: 1,
      secure: true,
    },
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  aws_appsync_graphqlEndpoint: awsAppSyncSettings.graphqlEndpoint,
  aws_appsync_region: awsAppSyncSettings.region,
  aws_appsync_authenticationType: awsAppSyncSettings.authenticationType,
  aws_appsync_complexObjectsCredentials: (): any => Auth.currentCredentials(),
};

Amplify.configure(awsConfig);
