import React from 'react';
import classNames from 'classnames';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
  FormGroup,
  Label,
} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const formSchema = Yup.object().shape({
  url: Yup.string()
    .url('A valid url is required')
    .required('Url is required'),
});

interface LabelFormModalProps {
  onToggle: () => void;
  onSave: (values: any, actions: any) => void;
  media?: any;
  isOpen?: boolean;
  hasError?: boolean;
}

const MediaFormModal: React.FunctionComponent<LabelFormModalProps> = ({
  isOpen,
  onToggle,
  onSave,
  hasError,
}) => {
  function renderForm(existingValues?: any): React.ReactNode {
    return (
      <Formik
        initialValues={{
          url: existingValues?.url ?? '',
        }}
        validationSchema={formSchema}
        onSubmit={onSave}
      >
        {({ isSubmitting, errors, touched, status }) => (
          <Form translate={null as any}>
            <ModalBody>
              {(hasError || status === 'error') && (
                <Alert color="danger">Oops, there was an issue saving this</Alert>
              )}

              <FormGroup>
                <Label for="url">Media URL</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-link" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Field
                    id="url"
                    name="url"
                    type="url"
                    placeholder="https://xxx.com/xxx"
                    className={classNames('form-control', {
                      'is-invalid': !!errors?.url && touched?.url,
                    })}
                    autoFocus
                  />
                  <ErrorMessage name="url" component={FormFeedback} />
                </InputGroup>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary" disabled={isSubmitting}>
                Submit
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    );
  }

  const header = 'Create media';
  const content = renderForm();

  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>{header}</ModalHeader>
      {content}
    </Modal>
  );
};

export default MediaFormModal;
