import React from 'react';
import ReactMde from 'react-mde';
import Markdown from 'react-markdown';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { ErrorMessage, useField } from 'formik';
import { FormFieldProps } from './types';

const MarkdownEditorInput: React.FC<FormFieldProps> = ({ field }) => {
  const fieldId = field.id ?? field.name;

  const [formikField] = useField(field);
  const [selectedTab, setSelectedTab] = React.useState('write');

  return (
    <FormGroup>
      {field.label && <Label for={fieldId}>{field.label}</Label>}
      <ReactMde
        value={formikField.value}
        onChange={(value: any) => {
          formikField.onChange({ currentTarget: { name: field.name, value } });
        }}
        selectedTab={selectedTab as any}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={markdown => Promise.resolve(<Markdown source={markdown} />)}
      />
      <ErrorMessage name={fieldId} component={FormFeedback} />
    </FormGroup>
  );
};

export default MarkdownEditorInput;
