import React from 'react';
import { Formik } from 'formik';
import { Button, Col, Form as RSForm, Row } from 'reactstrap';
import { FormFieldDetails } from './types';
import Input from './Input';
import AddressInputGroup from './AddressInputGroup';
import HiddenInput from './HiddenInput';
import MarkdownEditorInput from './MarkdownEditorInput';
import LinksInput from './LinksInput';
import ConditionsEditor from './ConditionsEditor';
import Select from './Select';

interface FormProps {
  existingValues?: { [key: string]: any };
  onSubmit: (formValues: object, actions: any) => void;
  schema: any;
  fields: FormFieldDetails[];
}

const Form: React.FC<FormProps> = ({ existingValues, fields, onSubmit, schema }) => (
  <Formik initialValues={existingValues ?? {}} validationSchema={schema} onSubmit={onSubmit}>
    {({ isSubmitting, values, errors, touched, handleSubmit, setFieldValue }) => (
      <RSForm translate={null as any} onSubmit={handleSubmit}>
        {existingValues?.id && <HiddenInput field={{ name: 'id' }} />}
        {fields.map(field => {
          let InputType = Input;
          let extraProps = {};

          switch (field.type) {
            case 'address':
              InputType = AddressInputGroup;
              break;
            case 'hidden':
              InputType = HiddenInput;
              break;
            case 'markdown':
              InputType = MarkdownEditorInput;
              break;
            case 'links':
              InputType = LinksInput;
              break;
            case 'select':
            case 'multiselect':
              InputType = Select;
              break;
            case 'conditions':
              InputType = ConditionsEditor as any;
              extraProps = { labels: existingValues?.labels };
              break;
            default:
              break;
          }

          return (
            <InputType
              key={field.name}
              field={field}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              {...extraProps}
            />
          );
        })}
        <Row>
          <Col className="text-right">
            <Button type="submit" color="primary" disabled={isSubmitting}>
              Submit
            </Button>
          </Col>
        </Row>
      </RSForm>
    )}
  </Formik>
);

export default Form;
