/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type UpdateLabelInput = {
    clientMutationId?: string | null;
    description?: string | null;
    id: string;
    name?: string | null;
    parentId?: string | null;
};
export type LabelFormModalUpdateMutationVariables = {
    input: UpdateLabelInput;
};
export type LabelFormModalUpdateMutationResponse = {
    readonly updateLabel: {
        readonly node: {
            readonly id: string;
            readonly name: string;
            readonly description: string | null;
            readonly parentId: string | null;
        } | null;
    };
};
export type LabelFormModalUpdateMutation = {
    readonly response: LabelFormModalUpdateMutationResponse;
    readonly variables: LabelFormModalUpdateMutationVariables;
};



/*
mutation LabelFormModalUpdateMutation(
  $input: UpdateLabelInput!
) {
  updateLabel(input: $input) {
    node {
      id
      name
      description
      parentId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateLabelInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateLabelPayload",
    "kind": "LinkedField",
    "name": "updateLabel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Label",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parentId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LabelFormModalUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LabelFormModalUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "LabelFormModalUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation LabelFormModalUpdateMutation(\n  $input: UpdateLabelInput!\n) {\n  updateLabel(input: $input) {\n    node {\n      id\n      name\n      description\n      parentId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cdcebed29154ed867cd364a0be04c644';
export default node;
