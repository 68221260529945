import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { Alert, Button } from 'reactstrap';
import graphql from 'babel-plugin-relay/macro';
import { QueryRenderer, createFragmentContainer } from 'react-relay';
import relayEnvironment from '../../Relay';
import Spinner from '../spinner';
import { Base as BaseInput } from '../form/inputs';
import LabelsTree from './LabelsTree';
import { LabelsTreeGlobalProps } from './types';

const query = graphql`
  query LabelsTreeDataWrapperQuery($filters: GetLabelsFiltersInput) {
    getLabels(filters: $filters) {
      edges {
        node {
          id
          name
          description
          parentId
        }
      }
    }
  }
`;

type Direction = 'prev' | 'next';

type LabelsTreeDataWrapperProps = LabelsTreeGlobalProps;

interface LabelsTreeDataWrapperState {
  searchQuery: string;
  searchFocusOffset: number;
  searchResultCount: number;
}

class LabelsTreeDataWrapper extends PureComponent<
  LabelsTreeDataWrapperProps,
  LabelsTreeDataWrapperState
> {
  constructor(props: LabelsTreeDataWrapperProps) {
    super(props);

    this.state = {
      searchQuery: '',
      searchFocusOffset: 0,
      searchResultCount: 0,
    };
  }

  private onSearchChange = (event: React.FormEvent<HTMLInputElement>): void => {
    const { value } = (event as any).target as { value: string };

    this.setState({
      searchQuery: value,
    });
  };

  private onSearchClear = (): void => {
    this.setState({
      searchQuery: '',
      searchFocusOffset: 0,
      searchResultCount: 0,
    });
  };

  private searchFinishCallback = (matches: []): void => {
    this.setState({
      searchResultCount: matches.length,
    });
  };

  private onSearchFocus = (direction: Direction): void => {
    const { searchFocusOffset, searchResultCount } = this.state;
    let nextItem = direction === 'next' ? searchFocusOffset + 1 : searchFocusOffset - 1;

    if (nextItem >= searchResultCount) {
      nextItem = 0;
    } else if (nextItem < 0) {
      nextItem = searchResultCount - 1;
    }

    this.setState({
      searchFocusOffset: nextItem,
    });
  };

  private searchMethod = ({ node, searchQuery = '' }: any): boolean => {
    const title = node.title || '';
    const subtitle = node.subtitle || '';
    const titleLowercased = title.toLowerCase();
    const subtitleLowercased = subtitle.toLowerCase();
    const searchQueryLowercased = searchQuery.toLowerCase();

    return (
      searchQuery &&
      ((titleLowercased && titleLowercased.indexOf(searchQueryLowercased) >= 0) ||
        (subtitleLowercased && subtitleLowercased.indexOf(searchQueryLowercased)))
    );
  };

  private renderSearch(): React.ReactNode | null {
    const { canSearch } = this.props;
    const { searchQuery, searchResultCount } = this.state;
    const hasResults = searchResultCount > 0;
    const searchIcon = searchQuery ? (
      <button
        type="button"
        className="sortable-tree__search__input-clear"
        onClick={this.onSearchClear}
      >
        <i className="fa fa-close" />
      </button>
    ) : (
      <i className="sortable-tree__search__input-icon fa fa-search" />
    );
    let searchInput = null;

    if (canSearch) {
      searchInput = (
        <div className="sortable-tree__search mb-2">
          <div className="sortable-tree__search__input-container">
            <BaseInput
              name="sortableTreeSearch"
              type="text"
              placeholder="Search"
              values={Map({
                sortableTreeSearch: searchQuery,
              })}
              onChange={this.onSearchChange}
            />
            {searchIcon}
          </div>
          <div className="sortable-tree__search__buttons text-right">
            {hasResults && <strong className="ml-2">{`${searchResultCount} found`}</strong>}
            <Button
              className="sortable-tree__search__prev-button ml-2"
              disabled={!hasResults}
              onClick={() => this.onSearchFocus('prev')}
            >
              <i className="fa fa-chevron-left" />
            </Button>
            <Button
              className="sortable-tree__search__next-button ml-2"
              disabled={!hasResults}
              onClick={() => this.onSearchFocus('next')}
              size="sm"
            >
              <i className="fa fa-chevron-right" />
            </Button>
          </div>
        </div>
      );
    }

    return searchInput;
  }

  public render(): React.ReactNode {
    const { selectedLabels } = this.props;
    const { searchQuery, searchFocusOffset } = this.state;

    return (
      <>
        <QueryRenderer
          environment={relayEnvironment}
          query={query}
          variables={{}}
          render={({ error, props }: any) => {
            if (error) {
              return <Alert color="danger">Oops, there was a problem fetching labels</Alert>;
            }

            if (!props) {
              return <Spinner />;
            }

            const labels = props.getLabels.edges.map((label: any) => ({
              ...label.node,
              title: label.node.name,
            }));
            const treeData = getTreeFromFlatData({ flatData: labels, rootKey: 'null' });

            return (
              <>
                {this.renderSearch()}
                <LabelsTree
                  {...this.props}
                  treeData={treeData}
                  selectedLabels={selectedLabels}
                  searchQuery={searchQuery}
                  searchFocusOffset={searchFocusOffset}
                  searchFinishCallback={this.searchFinishCallback}
                  searchMethod={this.searchMethod}
                />
              </>
            );
          }}
        />
      </>
    );
  }
}

export const ManageLabelsTree = LabelsTreeDataWrapper;

export default createFragmentContainer(ManageLabelsTree, {
  selectedLabels: graphql`
    fragment LabelsTreeDataWrapper_selectedLabels on Label @relay(plural: true) {
      id
    }
  `,
});
