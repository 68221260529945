import React from 'react';
import classNames from 'classnames';
import { FieldArray } from 'formik';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { FormFieldProps } from './types';
import Input from './Input';

const LINK_PROVIDERS = [
  {
    label: 'Amazon',
    value: 'AMAZON',
  },
  {
    label: 'Netflix',
    value: 'NETFLIX',
  },
  {
    label: 'Hulu',
    value: 'HULU',
  },
  {
    label: 'YouTube',
    value: 'YOUTUBE',
  },
  {
    label: 'Google',
    value: 'GOOGLE',
  },
  {
    label: 'Google Play Store',
    value: 'GOOGLE_PLAY',
  },
  {
    label: 'Vudu',
    value: 'VUDU',
  },
  {
    label: 'Facebook',
    value: 'FACEBOOK',
  },
  {
    label: 'Steam',
    value: 'STEAM',
  },
  {
    label: 'Apple',
    value: 'APPLE',
  },
  {
    label: 'Apple App Store',
    value: 'APP_STORE',
  },
  {
    label: 'Website',
    value: 'WEBSITE',
  },
];

const LINK_PLATFORMS = [
  {
    label: 'Web',
    value: 'WEB',
  },
  {
    label: 'iOS',
    value: 'IOS',
  },
  {
    label: 'Android',
    value: 'ANDROID',
  },
];

const LinksInput: React.FC<FormFieldProps> = ({ field, values, errors, touched }) => {
  function renderContent(arrayHelpers: any): React.ReactNode {
    const links = values?.[field.name] ?? [];

    if (!links || links.length === 0) {
      return (
        <Button className="d-block" onClick={() => arrayHelpers.push({})}>
          Start adding links
        </Button>
      );
    }

    return links.map((link: any, index: number) => {
      const fieldIndexName = `${field.name}[${index}]`;
      const classes = classNames('p-1', {
        'bg-light': index % 2 === 0,
      });

      return (
        <Row form key={index} className={classes}>
          <Col lg={6} md={12}>
            <Input
              field={{
                name: `${fieldIndexName}.url`,
                label: 'URL',
                type: 'url',
              }}
              errors={errors}
              touched={touched}
            />
          </Col>
          <Col lg={6} md={12}>
            <Input
              field={{
                name: `${fieldIndexName}.text`,
                label: 'Custom text',
              }}
            />
          </Col>
          <Col lg={4} md={12}>
            <Input
              field={{
                name: `${fieldIndexName}.provider`,
                label: 'Provider',
                as: 'select',
                selectOptions: LINK_PROVIDERS,
              }}
            />
          </Col>
          <Col lg={3} md={12}>
            <Input
              field={{
                name: `${fieldIndexName}.cost`,
                label: 'Cost',
              }}
            />
          </Col>
          <Col lg={3} md={12}>
            <Input
              field={{
                name: `${fieldIndexName}.platform`,
                label: 'Platform',
                as: 'select',
                selectOptions: LINK_PLATFORMS,
              }}
            />
          </Col>
          <Col
            lg={2}
            md={12}
            className="mb-md-2 pt-lg-4 d-flex align-items-center justify-content-center"
          >
            <Button
              className="mr-2 flex-fill"
              color="danger"
              onClick={() => arrayHelpers.remove(index)}
            >
              <i className="fa fa-trash" />
            </Button>
            {index === links.length - 1 && (
              <Button className="flex-fill" onClick={() => arrayHelpers.insert(index + 1, {})}>
                <i className="fa fa-plus" />
              </Button>
            )}
          </Col>
        </Row>
      );
    });
  }

  return (
    <FieldArray
      name={field.name}
      render={(arrayHelpers): React.ReactNode => (
        <FormGroup>
          <Label>{field.label || 'Links'}</Label>
          {renderContent(arrayHelpers)}
        </FormGroup>
      )}
    />
  );
};

export default LinksInput;
