import React from 'react';
import { Col, Row } from 'reactstrap';
import { FormFieldProps } from './types';
import Input from './Input';

const AddressInputGroup: React.FC<FormFieldProps> = ({ errors, touched }) => (
  <>
    <Input
      field={{
        label: 'Street 1',
        name: 'street1',
        placeholder: '1234 Main St',
      }}
      errors={errors}
      touched={touched}
    />
    <Input
      field={{
        label: 'Street 2',
        name: 'street2',
        placeholder: 'Apartment, studio, or floor',
      }}
      errors={errors}
      touched={touched}
    />
    <Input
      field={{
        label: 'Country',
        name: 'country',
        as: 'select',
      }}
      errors={errors}
      touched={touched}
    >
      <option>Selete a country</option>
      <option value="United States">United States</option>
    </Input>
    <Row form>
      <Col md={6}>
        <Input
          field={{
            label: 'City',
            name: 'city',
          }}
          errors={errors}
          touched={touched}
        />
      </Col>
      <Col md={4}>
        <Input
          field={{
            label: 'State',
            name: 'state',
            as: 'select',
          }}
          errors={errors}
          touched={touched}
        >
          <option>Selete a state</option>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </Input>
      </Col>
      <Col md={2}>
        <Input
          field={{
            label: 'Postal code',
            name: 'postalCode',
          }}
          errors={errors}
          touched={touched}
        />
      </Col>
    </Row>
    <Row form>
      <Col md={6}>
        <Input
          field={{
            label: 'Latitude',
            name: 'latitude',
          }}
          errors={errors}
          touched={touched}
        />
      </Col>
      <Col md={6}>
        <Input
          field={{
            label: 'Longitude',
            name: 'longitude',
          }}
          errors={errors}
          touched={touched}
        />
      </Col>
    </Row>
  </>
);

export default AddressInputGroup;
