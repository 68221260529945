interface AwsAuthSettings {
  identityPoolId: string;
  clientId: string;
  userPoolId: string;
  region: string;
  cookieDomain: string;
}

interface AwsAppSyncSettings {
  graphqlEndpoint: string;
  region: string;
  authenticationType: string;
}

interface IEnvironment {
  setEnvironment(name: string): void;
  getName(): string;
  getVersion(): string;
  getApi(): string;
  getSentryId(): string;
  getGoogleAnalyticsId(): string;
  getAwsAuthSettings(): AwsAuthSettings;
  getAwsAppSyncSettings(): AwsAppSyncSettings;
  is(isThis: string): boolean;
  isProduction(): boolean;
  isPreProd(): boolean;
  isDevelopment(): boolean;
  isTest(): boolean;
  isAdmin(): boolean;
  isManage(): boolean;
}

class Environment implements IEnvironment {
  private name: string;

  constructor() {
    this.name = 'production';

    this.setEnvironment(process.env.NODE_ENV as string);
  }

  public setEnvironment = (environmentName: string): void => {
    this.name = environmentName;
  };

  public getName = (): string => this.name;

  public getVersion = (): string => process.env.REACT_APP_VERSION as string;

  public getApi = (): string => process.env.REACT_APP_API as string;

  public getSentryId = (): string => process.env.REACT_APP_SENTRY_ID as string;

  public getGoogleAnalyticsId = (): string => process.env.REACT_APP_GOOGLE_ANALYTICS as string;

  public getAwsAuthSettings = (): AwsAuthSettings => ({
    identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID as string,
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID as string,
    region: process.env.REACT_APP_AUTH_REGION as string,
    cookieDomain: this.isDevelopment()
      ? 'localhost'
      : (process.env.REACT_APP_AUTH_COOKIE_DOMAIN as string),
  });

  public getAwsAppSyncSettings = (): AwsAppSyncSettings => ({
    graphqlEndpoint: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT as string,
    region: process.env.REACT_APP_APPSYNC_REGION as string,
    authenticationType: process.env.REACT_APP_APPSYNC_AUTHENTICATION as string,
  });

  public is = (isThis: string): boolean => this.getName() === isThis;

  public isProduction = (): boolean => this.is('production');

  public isPreProd = (): boolean => this.is('preprod');

  public isDevelopment = (): boolean => this.is('development');

  public isTest = (): boolean => this.is('test');

  private getType = (): string => process.env.REACT_APP_TYPE as string;

  public isAdmin = (): boolean => this.getType() === 'admin';

  public isManage = (): boolean => this.getType() === 'manage';
}

export default new Environment();
