import { ReactNode } from 'react';
import { Alert, AlertLevel } from '../types';

export interface ISystemAlert {
  getLevel(): AlertLevel;
  getMessage(): ReactNode;
  isDismissable(): boolean;
  getDismissTime(): number | undefined;
}

export default class SystemAlert implements ISystemAlert {
  private alert: Alert;

  constructor(props: Alert) {
    this.alert = props;
  }

  public getLevel(): AlertLevel {
    return this.alert.level;
  }

  public getMessage(): ReactNode {
    return this.alert.message;
  }

  public isDismissable(): boolean {
    return !!this.alert.dismissable || this.getLevel() === AlertLevel.SUCCESS;
  }

  public getDismissTime(): number | undefined {
    return this.alert.dismissTime;
  }
}
