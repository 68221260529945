export enum MediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}
export interface Media {
  url: string;
  name?: string;
  description?: string;
  provider?: string;
  type?: MediaType;
}
